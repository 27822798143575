import React from 'react';
import Styles from './styles.module.css';
import 'firebase/auth'

type DetailsManageSummaryPcProps = {
  count: number,
  image: string,
  result1: number,
  result2: number,
  result3: number,
  result4: number,
  result5: number,
  result6: number,
  result7: number,
  result8: number,
};

const DetailsManageSummaryPc: React.FC<DetailsManageSummaryPcProps>
  = ({ count, image, result1, result2, result3, result4, result5, result6, result7, result8 }) => {

  var image = 'https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/profiles%2Fresize_images%2F' + image + '_200x200?alt=media&token=';

  return (
    <>
    <div className={Styles.box}>
      <img src={image} alt='' />
      {1 <= count && count <= 8 ?
        <p>{result1 === 4 ? '◎' : result1 === 3 ? '○' : result1 === 2 ? '△' : '×'}</p>
      : <></> }
      {2 <= count && count <= 8 ?
        <p>{result2 === 4 ? '◎' : result2 === 3 ? '○' : result2 === 2 ? '△' : '×'}</p>
      : <></> }
      {3 <= count && count <= 8 ?
        <p>{result3 === 4 ? '◎' : result3 === 3 ? '○' : result3 === 2 ? '△' : '×'}</p>
      : <></> }
      {4 <= count && count <= 8 ?
        <p>{result4 === 4 ? '◎' : result4 === 3 ? '○' : result4 === 2 ? '△' : '×'}</p>
      : <></> }
      {5 <= count && count <= 8 ?
        <p>{result5 === 4 ? '◎' : result5 === 3 ? '○' : result5 === 2 ? '△' : '×'}</p>
      : <></> }
      {6 <= count && count <= 8 ?
        <p>{result6 === 4 ? '◎' : result6 === 3 ? '○' : result6 === 2 ? '△' : '×'}</p>
      : <></> }
      {7 <= count && count <= 8 ?
        <p>{result7 === 4 ? '◎' : result7 === 3 ? '○' : result7 === 2 ? '△' : '×'}</p>
      : <></> }
      {8 <= count && count <= 8 ?
        <p>{result8 === 4 ? '◎' : result8 === 3 ? '○' : result8 === 2 ? '△' : '×'}</p>
      : <></> }
    </div>
    </>
  );
}

export default DetailsManageSummaryPc;
