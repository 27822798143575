import React from 'react';
import Styles from './styles.module.css';

type DetailsManageBarPcProps = {
  count: number,
};

const DetailsManageBarPc: React.FC<DetailsManageBarPcProps> = ({ count }) => {

  return (
    <>
    {1 <= count && count <= 8 ?
      <p className={Styles.box}>1</p>
    : <></> }
    {2 <= count && count <= 8 ?
      <p className={Styles.box}>2</p>
    : <></> }
    {3 <= count && count <= 8 ?
      <p className={Styles.box}>3</p>
    : <></> }
    {4 <= count && count <= 8 ?
      <p className={Styles.box}>4</p>
    : <></> }
    {5 <= count && count <= 8 ?
      <p className={Styles.box}>5</p>
    : <></> }
    {6 <= count && count <= 8 ?
      <p className={Styles.box}>6</p>
    : <></> }
    {7 <= count && count <= 8 ?
      <p className={Styles.box}>7</p>
    : <></> }
    {8 === count ?
      <p className={Styles.box}>8</p>
    : <></> }
    </>
  );
}

export default DetailsManageBarPc;
