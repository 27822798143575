import Styles from './styles.module.css';
import React, { useState, useEffect } from 'react';
import Resume from './resume/index';
import Photo from './photo/index';
import Manage from './manage/index';

import { fetchDetails } from '../../features/package/detailsPanel';
import { fetchRecruit } from '../../features/package/detailsPanel';
import { fetchApplicant } from '../../features/package/detailsPanel';

import { useDispatch, useSelector } from 'react-redux';

const DetailsPc: React.FC = () => {

  const dispatch = useDispatch();

  const company = useSelector((state: any) => state.company.company);
  const users = useSelector((state: any) => state.auth.users);

  const recruitList = useSelector((state: any) => state.detailsPanel.recruit);
  const applicantList = useSelector((state: any) => state.detailsPanel.applicant);

  const [ resumeAppearSwich, setResumeAppearSwich ] = useState(1);

  useEffect(() => {
    if (!company.administrator.includes(users.uid)) {
      setResumeAppearSwich(2);
    }
  }, []);

  return (
    <>
    <div className={Styles.box}>
      <div>
        <div className={Styles.box_black} 
          onClick={() => {dispatch(fetchDetails(false)); dispatch(fetchRecruit(recruitList.recruitId)); dispatch(fetchApplicant(applicantList.id));}}></div>
        <div className={Styles.box_info}>
          
          <div className={Styles.progress}>
            {company.administrator.includes(users.uid) ?
              <h4 style={resumeAppearSwich === 1 ? {borderBottom : '3px solid #AD0200', color: '#222222'} : {}}
                onClick={() => setResumeAppearSwich(1)}>評価</h4>
            : <></> }
            <h4 style={resumeAppearSwich === 2 ? {borderBottom : '3px solid #AD0200', color: '#222222'} : {}}
              onClick={() => setResumeAppearSwich(2)}>履歴書</h4>
            <h4 style={resumeAppearSwich === 3 ? {borderBottom : '3px solid #AD0200', color: '#222222'} : {}}
              onClick={() => setResumeAppearSwich(3)}>memorii photo</h4>
          </div>

          {resumeAppearSwich === 1 && company.administrator.includes(users.uid) ?
            <Manage />
          : <></> }

          {resumeAppearSwich === 2 ?
            <Resume
              recruitList={recruitList}
              applicantList={applicantList}
            />
          : <></> }

          {resumeAppearSwich === 3 ?
            <Photo uid={applicantList.uid} />
          : <></> }

        </div>
      </div>
    </div>
    </>
  );
}

export default DetailsPc;


