import Styles from './styles.module.css';
import 'firebase/auth';
import firebase from 'firebase/compat/app';
import Evaluation from "./evaluation/index";
import Bar from "./bar/index";
import Summary from "./summary/index";
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const DetailsManagePc: React.FC = () => {

  const navigate = useNavigate();

  const recruit = useSelector((state: any) => state.detailsPanel.recruit);
  const applicant = useSelector((state: any) => state.detailsPanel.applicant);

  const [ examData, setExamData ] = useState<ExamData[]>([]);
  const [ progress, setProgress ] = useState<number>(0);

  useEffect(() => {
    getEvaluation(applicant.examination);
  }, [applicant]);

  interface ExamData {
    id: string;
    uid: string;
    name: string;
    evaluation: number;
    evaluation1Score: number;
    evaluation2Score: number;
    evaluation3Score: number;
    evaluation4Score: number;
    evaluation5Score: number;
    evaluation6Score: number;
    evaluation7Score: number;
    evaluation8Score: number;
    evaluation1Comment: string;
    evaluation2Comment: string;
    evaluation3Comment: string;
    evaluation4Comment: string;
    evaluation5Comment: string;
    evaluation6Comment: string;
    evaluation7Comment: string;
    evaluation8Comment: string;
  }

  function getEvaluation(v: number) {
    if (applicant.id !== '' && recruit.selection !== undefined && recruit.selection !== 0) {
      firebase.firestore().collection('applicants').doc(applicant?.id).collection('examination').where("evaluation", '==', v).get()
      .then(snapShot => {
        const tmp: ExamData[] = [];
        if (!snapShot.empty) {
          snapShot.forEach((doc) => {
            tmp.push({
              id: doc.id,
              uid: doc.data()?.uid,
              name: doc.data()?.name,
              evaluation: doc.data()?.evaluation,
              evaluation1Score: doc.data()?.evaluation1Score,
              evaluation2Score: doc.data()?.evaluation2Score,
              evaluation3Score: doc.data()?.evaluation3Score,
              evaluation4Score: doc.data()?.evaluation4Score,
              evaluation5Score: doc.data()?.evaluation5Score,
              evaluation6Score: doc.data()?.evaluation6Score,
              evaluation7Score: doc.data()?.evaluation7Score,
              evaluation8Score: doc.data()?.evaluation8Score,
              evaluation1Comment: doc.data()?.evaluation1Comment,
              evaluation2Comment: doc.data()?.evaluation2Comment,
              evaluation3Comment: doc.data()?.evaluation3Comment,
              evaluation4Comment: doc.data()?.evaluation4Comment,
              evaluation5Comment: doc.data()?.evaluation5Comment,
              evaluation6Comment: doc.data()?.evaluation6Comment,
              evaluation7Comment: doc.data()?.evaluation7Comment,
              evaluation8Comment: doc.data()?.evaluation8Comment,
            });
          });
          setExamData(tmp);
        }
      })
    }
  };

  function updateExamination(value: number) {
    firebase.firestore().collection("applicants").doc(applicant.id).update({ examination: Number(value) })
    .then(() => getEvaluation(value));
  }

  return (
    <>
    {recruit.selection !== 0 ?
      <div>
        <h5 className={Styles.title}>選考</h5>
        <select className={Styles.select} value={progress} 
          onChange={(e) => {setProgress(Number(e.target.value)); updateExamination(Number(e.target.value));}}>
          {1 <= Number(recruit.selection) && Number(recruit.selection) <= 5 ?
            <option value={1}>1次選考</option>
          : <></> }
          {2 <= Number(recruit.selection) && Number(recruit.selection) <= 5 ?
            <option value={2}>2次選考</option>
          : <></> }
          {3 <= Number(recruit.selection) && Number(recruit.selection) <= 5 ?
            <option value={3}>3次選考</option>
          : <></> }
          {4 <= Number(recruit.selection) && Number(recruit.selection) <= 5 ?
            <option value={4}>4次選考</option>
          : <></> }
          {5 <= Number(recruit.selection) && Number(recruit.selection) <= 5 ?
            <option value={5}>5次選考</option>
          : <></> }
          <option value={6}>採用</option>
          <option value={7}>不採用</option>
        </select>

        <h5 className={Styles.title}>全体評価</h5>
        <div className={Styles.table}>

          <div className={Styles.all}>
            <p className={Styles.all_header}>審査員</p>
            <Bar count={recruit.selectionCount} />
          </div>

          {examData.map((data, index) =>
          <>
            <Summary 
              image={data.uid}
              count={Number(recruit.selection)}
              result1={Number(data.evaluation1Score)}
              result2={Number(data.evaluation2Score)}
              result3={Number(data.evaluation3Score)}
              result4={Number(data.evaluation4Score)}
              result5={Number(data.evaluation5Score)}
              result6={Number(data.evaluation6Score)}
              result7={Number(data.evaluation7Score)}
              result8={Number(data.evaluation8Score)}
            />
          </> )}
        </div>

        <h5 className={Styles.title}>審査員の評価・コメント</h5>
        {examData.map((data, index) =>
        <>
          {data.evaluation === progress ?
          <>
            <div className={Styles.items}>
              <img src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/profiles%2Fresize_images%2F${data.uid}_200x200?alt=media&token`} />
              <p>{data.name}</p>
            </div>

            {1 <= Number(recruit.selectionCount) && Number(recruit.selectionCount) <= 8 ?
              <Evaluation count={1} title={recruit.selectionText1} score={data.evaluation1Score} comment={data.evaluation1Comment} />
            : <></> }
            {2 <= Number(recruit.selectionCount) && Number(recruit.selectionCount) <= 8 ?
              <Evaluation count={2} title={recruit.selectionText2} score={data.evaluation2Score} comment={data.evaluation2Comment} />
            : <></> }
            {3 <= Number(recruit.selectionCount) && Number(recruit.selectionCount) <= 8 ?
              <Evaluation count={3} title={recruit.selectionText3} score={data.evaluation3Score} comment={data.evaluation3Comment} />
            : <></> }
            {4 <= Number(recruit.selectionCount) && Number(recruit.selectionCount) <= 8 ?
              <Evaluation count={4} title={recruit.selectionText4} score={data.evaluation4Score} comment={data.evaluation4Comment} />
            : <></> }
            {5 <= Number(recruit.selectionCount) && Number(recruit.selectionCount) <= 8 ?
              <Evaluation count={5} title={recruit.selectionText5} score={data.evaluation5Score} comment={data.evaluation5Comment} />
            : <></> }
            {6 <= Number(recruit.selectionCount) && Number(recruit.selectionCount) <= 8 ?
              <Evaluation count={6} title={recruit.selectionText6} score={data.evaluation6Score} comment={data.evaluation6Comment} />
            : <></> }
            {7 <= Number(recruit.selectionCount) && Number(recruit.selectionCount) <= 8 ?
              <Evaluation count={7} title={recruit.selectionText7} score={data.evaluation7Score} comment={data.evaluation7Comment} />
            : <></> }
            {8 <= Number(recruit.selectionCount) && Number(recruit.selectionCount) <= 8 ?
              <Evaluation count={8} title={recruit.selectionText8} score={data.evaluation8Score} comment={data.evaluation8Comment} />
            : <></> }

          </> : <></> }

        </> )}

        <div className={Styles.border}></div>

        <button className={Styles.history}  
          // onClick={() => setHistory(true)}
        >評価の履歴</button>

        <div style={{height: '50px'}}></div>

      </div>
    :
      <div>
        <p className={Styles.setting_desc}>
          現在、選考評価の設定が行われていません。<br />応募者の設定を行う場合は以下の選考評価の設定ボタンより評価設定を行ってください。</p>
        <button className={Styles.setting_btn}
          onClick={() => navigate(`/edit/evaluation/id=${recruit.companyId}+page=2+recruitId=${recruit.id}`)}
        >選考評価の設定</button>
        <p className={Styles.setting_attention}>※選考評価の設定は「求人管理画面 {">"} 求人 {">"} 詳細ボタン {">"} 評価設定」より設定を行うことが出来ます。</p>
      </div>
    }
    </>
  );
}

export default DetailsManagePc;
